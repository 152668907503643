import React, { useEffect, useState } from "react";
import { Download } from "react-iconly";
import baseUrl from "../../../../../Http/backend-base-url";
import { useTranslation } from "react-i18next";
import api from "../../../../../Http/global-vars";
import DatePicker from "react-datepicker";
import axios from "axios";
import moment from "moment";
import { registerLocale } from "react-datepicker";
import { fr, enUS } from "date-fns/locale";

let lng = window.localStorage.getItem("i18nextLng");

registerLocale(lng === "en" ? "en" : "fr", lng === "en" ? enUS : fr);

function ModalExtraction() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [date, setDate] = useState(new Date());
  const [stats, setStats] = useState({
    connected_users: 0,
    date: "",
    documents: 0,
    guides: 0,
    messages: 0,
    ressources: 0,
    unvestity_catalog: 0,
    utilisateurs: 0,
    videos: 0,
  });
  
  const getStat = async (date) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
      },
    };
    const url =
       `${api}stats/?date=${moment(date).format("DD-MM-YYYY")}&filter=year`;
    await axios
      .get(url, config)
      .then(async (res) => {
        // const data = await res.json();
        console.log("data dashboard", res);
        setStats(res?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("error", error?.response);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getStat(date);
  }, [date]);

  return (
    <div
      className="modal fade"
      id={`extractionModal`}
      tabIndex="-1"
      data-backdrop="static"
      aria-labelledby="extractionModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content">
          <div className="modal-header py-3">
            <h5 className="modal-title text-titre" id="addCommentModal">
              Statistiques de {moment(date).locale(lng).format("MMMM/YYYY")} 
            </h5>
            <button
              type="button"
              className="close btn-close-modal-comment"
              data-dismiss="modal"
              aria-label="Close"
              //   onClick={() => handleReset()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div>
            <div className="modal-body">
              <div className="bloc-entete-document py-4 d-sm-flex align-items-center mb-3 justify-content-end">
                <div className="flex-r w-100" style={{ gap: "1rem" }}>
                  <DatePicker
                    dateFormat={"MMMM/yyyy"}
                    className="form-control chart-date w-100"
                    selected={date}
                    onChange={(date) => setDate(date)}
                    locale={lng === "en" ? "en" : "fr"}
                  />
                  <a
                    className="btn btn-download-document"
                    href={`${api}export_stats/?date=${moment(date).format("DD-MM-YYYY")}&filter=year`}
                    download
                    style={{ width: "max-content", borderRadius: "100px" }}
                    target="_blank"
                  >
                    <Download set="bold" primaryColor="#003DA5" />
                    <span className="pl-2"> {t("download")} </span>
                  </a>
                </div>
              </div>
              <div>
                <div className="row mb-3 px-2">
                  {/* {isLoading && <CardSkeleton />} */}
                  {!isLoading && (
                    <React.Fragment>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                        <div className="card-statistics card-statistics-color-1">
                          <div className="stat-conteny">
                            <p className="child-label-card-statistics"></p>
                            <p className="parent-label-card-statistics">
                              {t("nbr_connexion")}
                            </p>
                          </div>
                          <div className="stat-counter">
                            <div className="label-stat-counter">
                              {stats?.connected_users}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                        <div className="card-statistics card-statistics-color-2">
                          <div className="stat-conteny">
                            <p className="child-label-card-statistics"></p>
                            <p className="parent-label-card-statistics">
                              {t("nbr_ressource")}
                            </p>
                          </div>
                          <div className="stat-counter">
                            <div className="label-stat-counter">
                              {stats?.ressources}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                        <div className="card-statistics card-statistics-color-3">
                          <div className="stat-conteny">
                            <p className="child-label-card-statistics"></p>
                            <p className="parent-label-card-statistics">
                              {t("nbr_user")}
                            </p>
                          </div>
                          <div className="stat-counter">
                            <div className="label-stat-counter">
                              {stats?.utilisateurs}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                        <div className="card-statistics card-statistics-color-1">
                          <div className="stat-conteny">
                            <p className="child-label-card-statistics"></p>
                            <p className="parent-label-card-statistics">
                              {t("nbr_catalog")}
                            </p>
                          </div>
                          <div className="stat-counter">
                            <div className="label-stat-counter">
                              {stats?.unvestity_catalog}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                        <div className="card-statistics card-statistics-color-2">
                          <div className="stat-conteny">
                            <p className="child-label-card-statistics"></p>
                            <p className="parent-label-card-statistics">
                              {t("nbr_message")}
                            </p>
                          </div>
                          <div className="stat-counter">
                            <div className="label-stat-counter">
                              {stats?.messages}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                        <div className="card-statistics card-statistics-color-3">
                          <div className="stat-conteny">
                            <p className="child-label-card-statistics"></p>
                            <p className="parent-label-card-statistics">
                              {t("nbr_guide")}
                            </p>
                          </div>
                          <div className="stat-counter">
                            <div className="label-stat-counter">
                              {stats?.guides}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*  */}
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                        <div className="card-statistics card-statistics-color-1">
                          <div className="stat-conteny">
                            <p className="child-label-card-statistics"></p>
                            <p className="parent-label-card-statistics">
                              {t("nbr_document")}
                            </p>
                          </div>
                          <div className="stat-counter">
                            <div className="label-stat-counter">
                              {stats?.documents}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                        <div className="card-statistics card-statistics-color-2">
                          <div className="stat-conteny">
                            <p className="child-label-card-statistics"></p>
                            <p className="parent-label-card-statistics">
                              {t("nbr_video")}
                            </p>
                          </div>
                          <div className="stat-counter">
                            <div className="label-stat-counter">
                              {stats?.videos}
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    </React.Fragment>
                  )}
                </div>
              </div>
              {/* <div className="pb-5 pt-3 text-center">
                <h1 className="h3-theme">{document?.title} </h1>
              </div>
              <div className="pb-5 mb-5">
                <p className="text-paragraph" style={{ color: "#000000" }}>
                  {document?.description}
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalExtraction;
